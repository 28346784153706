exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jesseynjau-tsx": () => import("./../../../src/pages/jesseynjau.tsx" /* webpackChunkName: "component---src-pages-jesseynjau-tsx" */),
  "component---src-pages-newsfeed-tsx": () => import("./../../../src/pages/newsfeed.tsx" /* webpackChunkName: "component---src-pages-newsfeed-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-termsandconditions-tsx": () => import("./../../../src/pages/termsandconditions.tsx" /* webpackChunkName: "component---src-pages-termsandconditions-tsx" */)
}

